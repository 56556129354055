export const config = {
    name: 'costomerSeries',
    searchFields: [
        {
            name: 'TOP客户系编码',
            value: 'topCustomerGroupCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户系名称',
            value: 'customerGroupName',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户系编码',
            value: 'customerGroupCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户等级(计算)',
            value: 'calCustomerLevelCode',
            type: 'select',
            isFixed: true,
            optionNum: 'NEOCRM_CUSTOMER_LEVEL',
            // options: [
            //     { value: 2, label: '普通' },
            //     { value: 4, label: 'TOP' }
            // ],
            span: 3
        },
        {
            name: '状态',
            value: 'enableFlag',
            type: 'select',
            optionNum: 'SYS_BMS_EX_RELATION_STATUS',
            isFixed: true,
            span: 3
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: 'TOP客户系编码',
                prop: 'topCustomerGroupCode',
                minWidth: 150
            },
            {
                label: '客户系名称',
                prop: 'customerGroupName',
                minWidth: 150
            },
            {
                label: '客户系编码',
                prop: 'customerGroupCode',
                minWidth: 150
            },
            {
                label: '客户等级(计算)',
                prop: 'calCustomerLevelCode',
                type: 'select',
                optionsKey: 'NEOCRM_CUSTOMER_LEVEL',
                minWidth: 120
            },
            {
                label: '客户经理',
                prop: 'customerManagerName',
                minWidth: 150
            },
            {
                label: '客户经理MIP',
                prop: 'customerManager',
                minWidth: 150
            },
            {
                label: '行业大类',
                prop: 'industryCategory',
                type: 'select',
                optionsKey: 'SRM_SUPPLIER_INDUSTRY',
                minWidth: 150
            },
            {
                label: '行业类型',
                prop: 'industryType',
                type: 'select',
                optionsKey: 'SYS_INDUSTRY_TYPE',
                minWidth: 150
            },
            {
                label: '状态',
                prop: 'enableFlag',
                type: 'select',
                optionsKey: 'SYS_BMS_EX_RELATION_STATUS',
                minWidth: 150
            },
            {
                label: '创建人mip',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建人名称',
                prop: 'createUserName',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                minWidth: 150
            },
            {
                label: '修改人mip',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改人名称',
                prop: 'updateUserName',
                minWidth: 150
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                minWidth: 150
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6
};
