import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 合同客户列表
export const getCustomerGroupInfoPage = (params) => {
    return request({
        url: apiCrmHost + '/customer/group/info/page',
        method: 'get',
        params
    });
};
